.steps {
  display: flex;
  flex-direction: column;
  position: relative;
}

.step {
  margin-bottom: var(--spacing-4);
  display: flex;
  flex-direction: row;
  align-items: center;
  &:after {
    content: '';
    border: 2px solid var(--color-tarmac-grey-50);
    left: 10px;
    position: absolute;
    top: var(--spacing-6);
    bottom: var(--spacing-8);
    z-index: -1;
  }
}

.text {
  flex: 0 0 calc(100% - 64px);
}

.circle {
  margin-right: var(--spacing-4);
  fill: var(--color-tarmac-grey-50);

  &[data-is-first-step='true'] {
    fill: var(--color-mica-blue-500);
  }
}

.summaryCard {
  display: block;

  @media (--tablet-l) {
    display: none;
  }
}

.sidebar {
  display: none;

  @media (--tablet-l) {
    display: block;
  }
}
