.wrapper {
  border: 1px solid var(--color-tarmac-grey-100);
  display: flex;
  gap: var(--spacing-4);
  margin-bottom: var(--spacing-10);
  padding: var(--spacing-4);

  @media (--desktop) {
    min-height: 130px;
  }

  & .info {
    width: 60%;

    @media (--mobile) {
      width: 100%;
    }

    & p {
      margin: 0;

      &:first-child {
        margin-bottom: var(--spacing-2);
      }
    }
  }

  & .map {
    width: 40%;

    @media (--mobile) {
      display: none;
    }
  }
}
