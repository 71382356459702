.introText {
  & h4 {
    margin: 0;
  }
  & p {
    margin-bottom: var(--spacing-10);
  }
}

.divider {
  border: none;
  border-bottom: 1px solid var(--color-neutral-100);
  margin-bottom: var(--spacing-4);
}

.financingWrapper {
  background-color: var(--color-primary-50);
  border: 1px solid var(--color-primary-500);
  padding: var(--spacing-3);

  & p {
    margin: 0;
  }
}
