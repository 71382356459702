.stage {
  @media (--mobile) {
    padding-top: var(--spacing-6);
  }

  &:global(.statusCard) {
    margin-bottom: 0 !important;
  }
}

.wrapper {
  margin-bottom: var(--spacing-10);

  h3 {
    font-size: 20px;
    margin: 0;
    margin-bottom: var(--spacing-10);
  }
}
