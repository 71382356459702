.infoIcon {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding-bottom: 2px;
}
.modalContainer {
  @media (--tablet) {
    width: 80%;
    height: auto;
    padding: 20px 0 36px 0;
  }
  @media (--tablet-l) {
    width: 800px;
  }
}
.modalBody {
  padding-top: 20px;
  line-height: 28px;
}
